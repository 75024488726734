import React from 'react';
// import Button from '../../UI/Button/Button';

import './Header.scss';

const Header = () => (
	<div className="Header">
		<p>Amalgama</p>
		{/* <Button color="secondary" size="large">CREAR PROYECTO</Button> */}
	</div>
);

export default Header;
