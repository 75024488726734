import React from 'react';
import Header from '../../components/Header/Header';
import HomeBody from '../../components/HomeBody/HomeBody';

import './Home.scss';

const Home = () => (
	<div className="Home">
		<Header />
		<HomeBody />
	</div>
);

export default Home;
