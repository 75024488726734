import React from 'react';
import Home from './screens/Home/Home';

import './App.scss';

function App() {
	return (
		<div className="App">
			<Home />
		</div>
	);
}

export default App;
