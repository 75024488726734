import React, { useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';

// import Button from '../../UI/Button/Button';
import FormInput from '../../UI/FormInput/FormInput';
// import Select from '../../UI/Select/Select';

import './HomeBody.scss';

const HomeBody = () => {
	const [ projectName, setProjectName ] = useState( '' );
	return (
		<div className="HomeBody">
			<div className="form-container">
				<FormInput
					label="Nombre del proyecto"
					value={projectName}
					onChange={setProjectName}
				/>
				<p style={{ fontSize: 16, marginBottom: 10 }}>
					Para crear un proyecto de Ruby On Rails escribí el siguiente comando en la terminal:
				</p>
				<CopyBlock
					text={`curl http://setup.amalgama.co/new-rails-project.sh | bash -s ${projectName}`}
					language="shell"
					showLineNumbers
					theme={dracula}
				/>
				<p style={{ marginTop: 10, marginBottom: 30 }}>
					Asegurate de tener instalado la última versión de Ruby y de Rails
				</p>
				<p style={{ fontSize: 16, marginBottom: 10 }}>
					Para crear un proyecto de ReactJS escribí el siguiente comando en la terminal:
				</p>
				<CopyBlock
					text={`curl http://setup.amalgama.co/new-react-project.sh | bash -s ${projectName}`}
					language="shell"
					showLineNumbers
					theme={dracula}
				/>
				{/* <Select label="Plataforma" /> */}
			</div>
			{/* <Button size="large">CREAR</Button> */}
		</div>
	);
};
export default HomeBody;
