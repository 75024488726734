/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormInput.scss';

const FormInput = ( {
	value, label, onChange, onBlur,
	onFocus, type, placeholder, name,
	disabled, error, autoComplete,
	className, required, noError,
	maxLength
} ) => (
	<div className={classNames( 'FormInput', { error: !!error, noError }, className )}>
		{!!label && <label htmlFor={name}>{label}{required && <span>*</span>}</label>}
		<input
			id={name}
			name={name}
			type={type}
			disabled={disabled}
			className={classNames( 'input', { error: !!error } )}
			value={value}
			onChange={event => onChange( event.target.value )}
			onBlur={onBlur}
			onFocus={onFocus}
			placeholder={placeholder}
			autoComplete={autoComplete}
			maxLength={maxLength}
		/>
		<p className={classNames( 'error-message', { visible: error && typeof error === 'string' } )}>
			{error}
		</p>
	</div>
);

FormInput.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	error: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.string ] ),
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	required: PropTypes.bool,
	noError: PropTypes.bool,
	maxLength: PropTypes.number
};

FormInput.defaultProps = {
	value: '',
	label: undefined,
	disabled: false,
	onChange: undefined,
	onBlur: undefined,
	onFocus: undefined,
	type: 'text',
	placeholder: '',
	error: false,
	autoComplete: undefined,
	className: '',
	required: false,
	noError: false,
	maxLength: undefined
};

export default FormInput;
